<template>
    <div class="market-layout">
        <div class="head">
            <div class="container">
                <h1 class="title">
                    {{ $i18n.get(`NFT Marketplace`) }}
                </h1>

                <div class="input-container">
                    <market-search></market-search>
                </div>
            </div>
        </div>

        <div class="tabs">
            <div class="container">
                <router-link :to="{ name: 'market' }" :tag="getTag('market')" class="link"
                :class="{ '-active': isActive('market') }">
                    {{ $i18n.get(`Home`) }}
                </router-link>

               <router-link :to="{ name: 'launchpad' }" :tag="getTag('launchpad')" class="link"
                            :class="{ '-active': isActive('launchpad') }">
                   {{ $i18n.get(`NFT Launchpad`) }}
               </router-link>

                <router-link :to="{ name: 'nfts-all' }" :tag="getTag('nfts')" class="link"
                :class="{ '-active': isActive('nfts-all') || isActive('nfts') }">
                    {{ $i18n.get(`All NFTs`) }}
                </router-link>

                <router-link :to="{ name: 'collections' }" :tag="getTag('collections')" class="link"
                :class="{ '-active': isActive('collections') }">
                    {{ $i18n.get(`Collections`) }}
                </router-link>

                <router-link :to="{ name: 'merchants' }" :tag="getTag('merchants')" class="link"
                :class="{ '-active': isActive('merchants') }">
                    {{ $i18n.get(`Official merchants`) }}
                </router-link>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import MarketSearch from './snippets/MarketSearch.vue'
import { mapActions } from 'vuex'

export default {
    name: 'market',
    components: {
        'market-search': MarketSearch,
    },
    data() {
        return {
            search: '',
        }
    },
    methods: {
        isActive(name) {
            return this.$route.name === name
        },
        getTag(name) {
            if (this.isActive(name)) {
                return 'h2';
            }
            return 'a';
        },
    }
}
</script>

<style lang="less">
    .market-layout {
        background-color: var(--main-grey);

        > .head {
            background-image: url(./images/bg-head.png);
            background-position: center;
            background-size: 2560px 100%;
            height: 140px;
            color: var(--white);
            z-index: 11;
            position: relative;

            > .container {
                width: var(--main-width-full);
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;

                > .title {
                    font-weight: bold;
                    font-size: 46px;
                    line-height: 144%;
                }

                > .input-container {
                    width: 500px;
                    position: relative;
                    z-index: 3;
                }
            }
        }

        > .tabs {
            background-color: var(--white);

            > .container {
                width: var(--main-width-full);
                height: 60px;
                margin: 0 auto;
                display: flex;
                align-items: center;

                > .link {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 144%;
                    margin-left: 30px;
                    border-bottom: 2px solid transparent;

                    &:first-child {
                        margin-left: 0;
                    }

                    &.-active {
                        color: var(--dark-blue);
                        border-bottom: 2px solid var(--blue-link);
                    }
                }
            }
        }
    }

    @media (max-width: 1279px) {
        .market-layout {
            > .head {
                > .container {

                    > .input-container {
                        width: 360px;
                    }
                }
            }
        }
    }

    @media (max-width: 1023px) {
        .market-layout {
            > .head {
                > .container {
                    column-gap: 50px;

                    > .title {
                        font-weight: bold;
                        font-size: 36px;
                        line-height: 144%;
                    }

                    > .input-container {
                        width: 300px;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .market-layout {
            > .head {
                height: auto;

                > .container {
                    width: 100%;
                    flex-direction: column;
                    row-gap: 8px;
                    padding: 15px;
                    justify-content: flex-start;

                    > .title {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 144%;
                    }

                    > .input-container {
                        width: 100%;
                        > .simple-search-input {
                            height: 44px;
                        }
                    }
                }
            }

            > .tabs {
                position: relative;

                > .container {
                    width: auto;
                    height: 50px;
                    padding: 0px 15px;
                    overflow-x: scroll;

                    > .link {
                        font-weight: 700;
                        font-size: 13px;
                        line-height: 20px;
                        margin-left: 15px;
                        white-space: nowrap;

                        &:first-child {
                            margin-left: 0;
                        }

                        &.-active {
                            color: var(--dark-blue);
                            border-bottom: 2px solid var(--blue-link);
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 0px;
                        height: 0px;
                        position: absolute;
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 100px;
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 2px;
                        background: var(--white);
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    background: linear-gradient(85.65deg, rgba(255, 255, 255, 0) -24.95%, white 146.19%);
                    width: 40px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
</style>
