<template>
    <div class="market-home">
        <div class="container">
            <banner-block v-if="isLoadBanners" :banners="banners"></banner-block>
            <banner-preloader v-else></banner-preloader>

            <div class="collections-block" v-if="isLoadCollections ? collections.length > 0 : true">
                <div class="head">
                    <h2 class="title">
                        {{ $i18n.get(`Top collections ✨`) }}
                    </h2>

                    <w-button v-if="!$store.state.isMobile" :to="{ name: 'collections' }" type="border">
                        {{ $i18n.get(`All collections`) }}
                    </w-button>
                </div>

                <div class="container">
                    <template v-if="isLoadCollections">
                        <card-collection v-for="collection of collections"
                            :key="collection.id"
                            :collection="collection">
                        </card-collection>
                    </template>

                    <template v-else>
                        <card-collection-preloader class="small" v-for="item of 3" :key="item"></card-collection-preloader>
                    </template>
                </div>

                <w-button v-if="$store.state.isMobile" :to="{ name: 'collections' }" :size="$store.state.isMobile ? 'small' : 'middle'"
                :block="$store.state.isMobile"
                type="border">
                    {{ $i18n.get(`All collections`) }}
                </w-button>
            </div>

            <div class="nft-block" v-if="isLoadNewNft ? newNft.length > 0 : true">
                <div class="head">
                    <h2 class="title">
                        {{ $i18n.get(`New items ⚡`) }}
                    </h2>

                    <w-button v-if="!$store.state.isMobile" :to="{ name: 'nfts-all' }" type="border">
                        {{ $i18n.get(`See all NFTs`) }}
                    </w-button>
                </div>

                <div class="container -middle">
                    <template v-if="isLoadNewNft">
                        <market-card-item v-for="(item, index) of newNft"
                            :key="index"
                            :item="item"
                            :view="$store.state.isMobile ? 'small': 'middle'">
                        </market-card-item>
                    </template>
                    <template v-else>
                        <market-card-item-preloader v-for="item of 12" :key="item"
                        :view="$store.state.isMobile ? 'small': 'middle'"></market-card-item-preloader>
                    </template>
                </div>

                <w-button v-if="$store.state.isMobile" :to="{ name: 'nfts-all' }" type="border" size="small" block >
                    {{ $i18n.get(`See all NFTs`) }}
                </w-button>
            </div>

            <div class="nft-block" v-if="isLoadTrendingNft ? trendingNft.length > 0 : true">
                <div class="head">
                    <h2 class="title">
                        {{ $i18n.get(`Trending 🔥`) }}
                    </h2>

                    <w-button v-if="!$store.state.isMobile" :to="{ name: 'nfts-all' }" type="border">
                        {{ $i18n.get(`Explore`) }}
                    </w-button>
                </div>

                <div class="container -large">
                    <template v-if="isLoadTrendingNft">
                        <market-card-item v-for="(item, index) of trendingNft"
                            :key="index"
                            :item="item"
                            :view="$store.state.isMobile ? 'middle': 'large'">
                        </market-card-item>
                    </template>
                    <template v-else>
                        <market-card-item-preloader v-for="item of 12" :key="item"
                        :view="$store.state.isMobile ? 'middle': 'large'"></market-card-item-preloader>
                    </template>
                </div>

                <w-button v-if="$store.state.isMobile" :to="{ name: 'nfts-all' }" type="border" size="small" block >
                    {{ $i18n.get(`Explore`) }}
                </w-button>
            </div>

            <div class="merchants-block">
                <div class="head">
                    <h2 class="title">
                        {{ $i18n.get(`Official merchants`) }}
                    </h2>

                    <w-button v-if="!$store.state.isMobile" :to="{ name: 'merchants' }" type="border">
                        {{ $i18n.get(`All merchants`) }}
                    </w-button>
                </div>

                <div class="container">
                    <template v-if="isLoadMerchants">
                        <card-merchant v-for="(merchant, index) of merchants"
                            :key="index"
                            class="small"
                            :merchant="merchant">
                        </card-merchant>
                    </template>

                    <template v-else>
                        <card-merchants-preloader class="small" v-for="item of 6" :key="item"></card-merchants-preloader>
                    </template>
                </div>

                <w-button v-if="$store.state.isMobile" :to="{ name: 'merchants' }" :size="$store.state.isMobile ? 'small' : 'middle'"
                :block="$store.state.isMobile"
                type="border">
                    {{ $i18n.get(`All merchants`) }}
                </w-button>
            </div>
        </div>

        <info-block></info-block>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Merchants from 'truefuture-ui/src/components/market/dashboard/merchants/Merchants.vue'
import CardMerchant from 'truefuture-ui/src/components/market/dashboard/merchants/snippets/CardMerchant.vue'
import CardMerchantsPreloader from 'truefuture-ui/src/components/market/dashboard/merchants/snippets/CardMerchantsPreloader.vue'
import InfoBlock from './snippets/InfoBlock.vue'
import BannerBlock from './snippets/BannerBlock.vue'
import CardCollection from 'truefuture-ui/src/components/market/dashboard/collections/snippets/CardCollection.vue'
import CardCollectionPreloader from 'truefuture-ui/src/components/market/dashboard/collections/snippets/CardCollectionPreloader.vue'
import BannerPreloader from './snippets/BannerPreloader.vue'

export default {
    name: 'market-home',
    components: {
        'merchants': Merchants,
        'card-merchant': CardMerchant,
        'card-merchants-preloader': CardMerchantsPreloader,
        'info-block': InfoBlock,
        'banner-block' : BannerBlock,
        'card-collection': CardCollection,
        'card-collection-preloader': CardCollectionPreloader,
        'banner-preloader': BannerPreloader,
    },
    computed: {
        ...mapGetters('market/dashboard',{
            banners: 'getBanners',
            isLoadBanners: 'isLoadBanners',
            collections: 'getCollections',
            isLoadCollections: 'isLoadCollections',
            newNft: 'getNewNft',
            isLoadNewNft: 'isLoadNewNft',
            trendingNft: 'getTrendingNft',
            isLoadTrendingNft: 'isLoadTrendingNft',
            merchants: 'getMerchants',
            isLoadMerchants: 'isLoadMerchants'
        })
    },
    methods: {
        ...mapActions('market/dashboard', {
            load: 'load'
        })
    },
    created() {
        this.load()
    }
}
</script>

<style lang="less">
    .market-home {
        > .container {
            width: var(--main-width-full);
            margin: 0 auto;

            > .collections-block {
                > .head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > .title {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 144%;
                    }
                }

                > .container {
                    margin-top: 30px;
                    -moz-column-gap: 20px;
                    column-gap: 20px;
                    row-gap: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 30px;

                    > .card-collection {
                        position: relative;

                        > .market-value {
                            background: linear-gradient(135deg, #757BFA 0%, #B448E9 100%);
                        }

                        &::before {
                            content: "1";
                            position: absolute;
                            top: 15px;
                            right: 15px;
                            width: 40px;
                            height: 40px;
                            border-radius: 0px 15px;
                            font-size: 16px;
                            font-weight: 800;
                            background-color: var(--white);
                            z-index: 1;
                            color: var(--dark-blue);
                            text-align: center;
                            padding: 13px;
                        }

                        &:nth-child(2) {
                            &::before {
                                content: "2"
                            }
                        }

                        &:nth-child(3) {
                            &::before {
                                content: "3"
                            }
                        }
                    }
                }
            }

            > .nft-block {
                margin-top: 60px;

                > .head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > .title {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 144%;
                    }
                }

                > .container {
                    margin-top: 40px;
                    display: flex;
                    flex-wrap: wrap;

                    &.-middle {
                        column-gap: 18px;
                        row-gap: 18px;
                    }

                    &.-large {
                        column-gap: 18px;
                        row-gap: 25px;
                    }
                }
            }

            > .merchants-block {
                margin-top: 60px;

                > .head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    > .title {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 144%;
                    }
                }

                > .container {
                    margin-top: 30px;
                    -moz-column-gap: 5px;
                    column-gap: 5px;
                    row-gap: 25px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                }

                > .btn {
                    margin-top: 15px;
                }
            }
        }
    }

    @media (max-width: 1279px) {
        .market-home {
            > .container {
                > .collections-block {
                    > .container {
                        column-gap: 18px;

                        > .card-collection {
                            &:nth-child(6) {
                                display: none;
                            }
                        }
                    }
                }
            }

            > .container {
                > .merchants-block {
                    > .container {
                        column-gap: 11px;
                        row-gap: 0px;
                    }
                }
            }
        }
    }

    @media (max-width: 1023px) {
        .market-home {
            > .container {
                width: var(--main-width-full);
                margin: 0 auto;

                > .collections-block {
                    > .container {
                        > .card-collection {
                            &:nth-child(5) {
                                display: none;
                            }
                        }
                    }
                }

                > .collections-block {
                    > .container {
                        > .card-collection-preloader {
                            &:nth-child(5) {
                                display: none;
                            }
                        }
                    }
                }

                > .nft-block {
                    margin-top: 60px;

                    > .head {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        > .title {
                            font-weight: 700;
                            font-size: 36px;
                            line-height: 144%;
                        }
                    }

                    > .container {
                        margin-top: 40px;
                        display: flex;
                        flex-wrap: wrap;

                        &.-middle {
                            > * {
                                width: calc(50% - 9px);
                            }
                        }

                        &.-large {
                            column-gap: 18px;
                            row-gap: 25px;
                        }
                    }
                }

                > .merchants-block {
                    > .container {
                        column-gap: 5px;
                        row-gap: 0px;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .market-home {
            padding: 15px;

            > .container {
                width: var(--main-width-full);
                margin: 0 auto;

                > .collections-block {
                    margin-top: 20px;
                    > .head {
                        > .title {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    > .container {
                        margin-top: 10px;

                        > .card-collection-preloader {
                            &:last-child {
                                display: flex;
                            }
                        }

                        > .card-collection {
                            &:last-child {
                                display: flex;
                            }
                        }
                    }
                }

                > .nft-block {
                    margin-top: 20px;
                    margin-bottom: 20px;

                    > .head {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        > .title {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 30px;
                        }

                        > .btn {
                            display: none;
                        }
                    }

                    > .container {
                        margin-top: 10px;
                        display: flex;
                        flex-wrap: wrap;

                        &.-middle {
                            row-gap: 10px;
                            flex-direction: column;

                            > * {
                                width: 100%;
                                display: none;

                                &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
                                    display: flex;
                                }
                            }
                        }

                        &.-large {
                            column-gap: 10px;
                            row-gap: 10px;
                        }
                    }

                    > .btn {
                        margin-top: 15px;
                    }
                }

                > .merchants-block {
                    padding-bottom: 30px;
                    margin-top: 30px;

                    > .container {
                        flex-wrap: inherit;
                        overflow: auto;
                        margin: 10px -15px 0px;
                        padding: 0px 15px;

                        &::-webkit-scrollbar {
                            width: 0;
                        }
                    }

                    > .head {
                        position: relative;

                        > .title {
                            font-weight: 700;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
</style>
