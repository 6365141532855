<template>
    <div class="search-page">
        <div class="head">
            <div class="title">
                {{ $i18n.get(`Search results for «{search}»`, {search: search}) }}
            </div>

            <div class="tabs" v-if="!loading">
                <div v-if="nft > 0"
                @click="active = 'nfts'" class="chapter" :class="{ '-active': active === 'nfts'}">
                    {{ $i18n.get(`NFT items`) }}

                    <span>
                        {{ nft }}
                    </span>
                </div>

                <div v-if="collections > 0"
                @click="active = 'collections'" class="chapter" :class="{ '-active': active === 'collections'}">
                    {{ $i18n.get(`Collections`) }}

                    <span>
                        {{ collections }}
                    </span>
                </div>

                <div v-if="merchants > 0"
                @click="active = 'merchants'" class="chapter" :class="{ '-active': active === 'merchants'}">
                    {{ $i18n.get(`Creators`) }}

                    <span>
                        {{ merchants }}
                    </span>
                </div>
            </div>

            <template v-else>
                <div class="tabs">
                    <div class="chapter-preloader"></div>
                    <div class="chapter-preloader"></div>
                    <div class="chapter-preloader"></div>
                </div>
            </template>
        </div>

        <div class="container" v-if="!loading">
            <nfts
                v-if="active ==='nfts'"
                :search="search"
                :is-show-categories="false"
            ></nfts>

            <collections :search="search" v-else-if="active === 'collections'"></collections>

            <merchants :search="search" v-else-if="active === 'merchants'" ></merchants>

            <div v-else class="results-not-found">
                <div class="icon">
                    <svg width="67" height="54" viewBox="0 0 67 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3708 0C17.2195 0 15.0333 2.1489 15.0333 6.24385V38.2436C15.0333 42.3385 17.2195 44.4874 21.3708 44.4874H31.8324C29.6124 41.5525 28.2969 37.9045 28.2969 33.9515C28.2969 24.547 35.7423 16.8691 45.0998 16.4121V6.24385C45.0998 2.1489 42.9136 0 38.7623 0H21.3708ZM60.1331 13.6584V23.4146C57.4523 19.8711 53.4528 17.3673 48.8581 16.6215V7.41457H53.7955C57.9468 7.41457 60.1331 9.56347 60.1331 13.6584ZM6.33755 7.41457C2.18623 7.41457 0 9.56347 0 13.6584V30.829C0 34.924 2.18623 37.0729 6.33755 37.0729H11.275V7.41457H6.33755ZM44.4675 46.6075C47.692 46.9548 50.9286 46.0701 53.5259 44.1314V44.1351L62.8721 53.4641C63.2163 53.8071 63.6828 53.9996 64.1691 53.9993C64.6554 53.9989 65.1216 53.8058 65.4653 53.4623C65.8089 53.1188 66.0017 52.6531 66.0014 52.1677C66.001 51.6823 65.8075 51.2169 65.4634 50.8739L56.1172 41.5449C58.0595 38.9523 58.9458 35.7216 58.5978 32.5031C58.2499 29.2845 56.6935 26.3169 54.2418 24.1976C51.7902 22.0783 48.6253 20.9645 45.3842 21.0805C42.1431 21.1965 39.0662 22.5335 36.7729 24.8226C34.4796 27.1117 33.1401 30.1829 33.0239 33.4181C32.9077 36.6533 34.0235 39.8123 36.1467 42.2595C38.2699 44.7066 41.2429 46.2601 44.4675 46.6075ZM52.3229 27.4095C54.0413 29.1248 55.0067 31.4511 55.0067 33.8768C55.0067 36.3025 54.0413 38.6289 52.3229 40.3441C50.6045 42.0593 48.2739 43.0229 45.8437 43.0229C43.4136 43.0229 41.0829 42.0593 39.3646 40.3441C37.6462 38.6289 36.6808 36.3025 36.6808 33.8768C36.6808 31.4511 37.6462 29.1248 39.3646 27.4095C41.0829 25.6943 43.4136 24.7307 45.8437 24.7307C48.2739 24.7307 50.6045 25.6943 52.3229 27.4095Z" fill="#001A54" fill-opacity="0.2"/>
                    </svg>
                </div>

                <div>
                    {{ $i18n.get(`Sorry, nothing matched to «{search}»`, {search: search}) }}
                </div>

                <div>
                    {{ $i18n.get(`Please try a different search or browse marketplace.`) }}
                </div>

                <w-button type="blue" :size="$store.state.isMobile ? 'small' : 'middle'" :to="{ name: 'market' }">
                    {{ $i18n.get(`Browse marketplace`) }}
                </w-button>
            </div>
        </div>
    </div>
</template>

<script>
import Nfts from 'truefuture-ui/src/components/market/dashboard/nfts/Nfts.vue'
import Collections from 'truefuture-ui/src/components/market/dashboard/collections/Collections.vue'
import Merchants from 'truefuture-ui/src/components/market/dashboard/merchants/Merchants.vue'

export default {
    name: 'search',
    data() {
        return {
            active: 'nfts',
            search: '',
            loading: true,
            nft: '',
            collections: '',
            merchants: '',
        }
    },
    components: {
        'collections': Collections,
        'merchants' : Merchants,
        'nfts' : Nfts
    },
    created() {
        if (!!this.$route.query.search) {
            this.search = this.$route.query.search
        } else {
            this.$router.push({ name: 'market'} )
        }

        this.$request.get('/market/search', { search: this.search}, response => {
            this.merchants = response.merchants.count
            this.collections = response.collections.count
            this.nft = response.nft.count

            if (parseInt(response.nft.count) > 0) {
                this.active = 'nfts'
            } else if (response.collections.count > 0) {
                this.active = 'collections'
            } else if (response.merchants.count > 0) {
                this.active = 'merchants'
            } else {
                this.active = ''
            }

            this.loading = false
        })
    }
}
</script>

<style lang="less">
.search-page {
    width: var(--main-width-full);
    margin: 50px auto;

    > .head {
        > .title {
            font-weight: 700;
            font-size: 36px;
            margin-bottom: 15px
        }

        > .tabs {
            display: flex;
            font-size: 16px;
            font-weight: 600;
            column-gap: 30px;
            width: 100%;
            border-bottom: rgba(0, 26, 84, 0.1) 1px solid;

            > .chapter {
                cursor: pointer;
                position: relative;
                padding-bottom: 7px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: var(--blue-link);
                    opacity: 0;
                    transition: opacity .3s;
                }

                &.-active {
                    color: var(--dark-blue-40);
                    transition: 0.3s;

                    &:after {
                        opacity: 1;
                    }
                }

                > span {
                    font-weight: 400;
                    color: var(--dark-blue-40);
                }
            }

            > .chapter-preloader {
                cursor: pointer;
                margin-bottom: 7px;
                width: 100px;
                height: 21px;
                background-color: var(--preloader-background);
                border-radius: 30px;
            }
        }
    }

    > .container {
        width: 100%;

        > .results-not-found {
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 25px;

            > .icon {
                margin-bottom: 20px;
                width: 66px;

                > img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            > div {
                width: fit-content;
                margin: auto;
                font-size: 16px;
                font-weight: 400;
                text-align: center;
            }

            > .btn {
                display: flex;
                width: fit-content;
                margin: 20px auto;
            }
        }
    }
}

@media (max-width: 767px) {
    .search-page{
        padding: 0 15px;
        margin: 15px 0px;

        > .head {
            > .title {
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 15px
            }

            > .tabs {
                font-size: 12px;

                 > .chapter-preloader {
                    width: 75px;
                    height: 16px;
                }
            }
        }

        > .container {
            margin: 0px -15px;
            width: calc(100% + 30px);

            > .results-not-found {
                margin-top: 50px;

                > .icon {
                    margin-bottom: 5px;
                    width: 55px;
                }

                > div {
                    font-size: 12px;
                    line-height: 20px;
                }

                > button {
                    width: fit-content;
                    margin-top: 15px;
                }
            }
        }
    }
}
</style>
