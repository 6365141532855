<template>
    <div class="simple-search-input" :class="{ '-moz': isMozilla }" :id="id">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" :id="id">
            <path d="M7.69765 15.3945C9.40555 15.3941 11.0642 14.8224 12.4096 13.7704L16.6394 18L18 16.6395L13.7701 12.4099C14.8228 11.0645 15.3949 9.40549 15.3953 7.69724C15.3953 3.45318 11.9419 0 7.69765 0C3.45336 0 0 3.45318 0 7.69724C0 11.9413 3.45336 15.3945 7.69765 15.3945ZM7.69765 1.92431C10.8816 1.92431 13.4709 4.51347 13.4709 7.69724C13.4709 10.881 10.8816 13.4702 7.69765 13.4702C4.51371 13.4702 1.92441 10.881 1.92441 7.69724C1.92441 4.51347 4.51371 1.92431 7.69765 1.92431Z" fill="white" :id="id"/>
        </svg>

        <input v-model="search" type="search" @keydown.enter="pushToSearchPage()"  @input="dispathSearch" @focus="openTooltip()" :placeholder="$i18n.get(`Search items & creators`)" :id="id">

        <svg v-if="search.length > 0" @click.prevent="clearModel()" class="clear" :id="id"
            width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.774464 13.4202C0.471931 13.1106 0.472833 12.6158 0.776492 12.3072L12.3203 0.577719C12.6325 0.260502 13.1443 0.261434 13.4553 0.579786C13.7578 0.889434 13.7569 1.38425 13.4533 1.69279L1.9095 13.4223C1.59731 13.7395 1.0855 13.7386 0.774464 13.4202Z" fill="#99A3BB"/>
            <path d="M13.2255 13.4202C13.5281 13.1106 13.5272 12.6158 13.2235 12.3072L1.67972 0.577719C1.36753 0.260502 0.855723 0.261434 0.544686 0.579786C0.242153 0.889434 0.243054 1.38425 0.546713 1.69279L12.0905 13.4223C12.4027 13.7395 12.9145 13.7386 13.2255 13.4202Z" fill="#99A3BB"/>
        </svg>

        <template v-if="tooltipIsOpen && (nft.count > 0 || collections.count > 0 || merchants.count > 0)">
            <transition name="fade">
                <div v-if="!loading" class="search-popup" :id="id" key="main">
                    <div v-if="collections.list.length > 0" class="group" :id="id">
                        <div class="title" :id="id">
                            {{ $i18n.get(`Colletions `) }}
                        </div>

                        <router-link class="item -collection" v-for="(collection, index) of collections.list" :key="index" :id="id"
                        :to="{ name: 'collection', params: { id: collection.id } }">
                            <div class="image-container" :id="id">
                                <img :src="collection.icon">
                            </div>
                            <div class="content" :id="id">
                                <div class="title" :id="id">
                                    {{ collection.title }}
                                </div>
                                <div class="items" :id="id">
                                    {{ $i18n.get(`{ itemsCount, plural, =0{No items} =1{1 item} other{# items} }`, {itemsCount: collection.nftCount}) }}
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div v-if="merchants.list.length > 0" class="group" :id="id">
                        <div class="title" :id="id">
                            {{ $i18n.get(`Creators`) }}
                        </div>

                        <router-link class="item -creator" v-for="(merchant, index) of merchants.list" :key="index" :id="id"
                        :to="{ name: 'merchant', params: { id: merchant.slug } }">
                            <div class="image-container" :id="id">
                                <img :src="merchant.image">
                            </div>

                            <div class="content" :id="id">
                                <div class="title" :id="id">
                                    {{ merchant.title }}

                                    <svg v-if="merchant.isVerified" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.99026 1.7376C8.78759 0.819937 10.2124 0.819936 11.0097 1.7376L11.2094 1.96735C11.6619 2.4882 12.351 2.739 13.0324 2.6309L13.3331 2.58322C14.5337 2.39276 15.6252 3.30862 15.6461 4.52409L15.6513 4.82841C15.6632 5.5183 16.0299 6.15335 16.6214 6.50858L16.8823 6.66527C17.9245 7.29113 18.1719 8.69431 17.4066 9.63887L17.215 9.87536C16.7807 10.4115 16.6533 11.1336 16.8781 11.786L16.9773 12.0737C17.3734 13.2231 16.6609 14.457 15.4676 14.6887L15.1688 14.7467C14.4914 14.8782 13.9297 15.3495 13.6826 15.9937L13.5736 16.2779C13.1382 17.4129 11.7993 17.9003 10.7362 17.3106L10.47 17.163C9.86664 16.8284 9.13336 16.8283 8.52996 17.163L8.26379 17.3106C7.20069 17.9003 5.86179 17.4129 5.42641 16.2779L5.31741 15.9937C5.07029 15.3495 4.50856 14.8782 3.83122 14.7467L3.53243 14.6887C2.33905 14.457 1.62664 13.2231 2.02269 12.0737L2.12186 11.786C2.34665 11.1336 2.21932 10.4115 1.78496 9.87536L1.59336 9.63887C0.828098 8.69431 1.07552 7.29113 2.11769 6.66527L2.37862 6.50858C2.97014 6.15335 3.33678 5.5183 3.34866 4.82842L3.3539 4.5241C3.37482 3.30862 4.4663 2.39276 5.66695 2.58322L5.96756 2.6309C6.64902 2.739 7.33809 2.48821 7.79064 1.96735L7.99026 1.7376Z" fill="#255CE7"/>
                                        <path d="M6.5 9L8.5 11L12.5 7" stroke="white" stroke-width="1.5"/>
                                    </svg>
                                </div>
                                <div class="items" :id="id">
                                    {{ $i18n.get(`{ itemsCount, plural, =0{No items} =1{1 item} other{# items} }`, {itemsCount: merchant.minted}) }}
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div v-if="nft.list.length > 0" class="group" :id="id">
                        <div class="title" :id="id">
                            {{ $i18n.get(`Items`) }}
                        </div>

                        <router-link class="item -item" v-for="(item, index) of nft.list" :key="index" :id="id"
                        :to="{ name: 'nft', params: { id: item.id } }">
                            <div class="image-container" :id="id">
                                <img :src="item.image">
                            </div>

                            <div class="content" :id="id">
                                <div class="title" :id="id">
                                    {{ item.title }}
                                </div>

                                <div class="creator" :id="id">
                                    {{ item.merchant.title }}
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <w-button v-if="nft.count > 4 || collections.count > 4 || merchants.count > 4" type="border" size="middle" block :id="id"
                    :to="{ name: 'search' , query: { search: search } }">
                        {{ $i18n.get(`Show all results`) }}
                    </w-button>
                </div>

                <div v-else class="search-popup -preloader">
                    <market-search-preloader v-for="item of 4" :key="item"></market-search-preloader>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import MarketSearchPreloader from './MarketSearchPreloader.vue'

export default {
    name: 'w-simple-search',
    components: {
        'market-search-preloader': MarketSearchPreloader
    },
    props: {
        id: {
            type: [ Number, String ],
            default: 'market-search'
        }
    },
    data() {
        return {
            search: '',
            tooltipIsOpen: false,
            loading: true,
            collections: [],
            nft: [],
            merchants: []
        }
    },
    computed: {
      isMozilla() {
        const userAgent = navigator.userAgent.toLowerCase()

        return /firefox/.test(userAgent)
      },
    },
    methods: {
        clearModel() {
            this.search = ''
        },
        openTooltip() {
            this.tooltipIsOpen = true
        },
        closeTooltip(event) {
            if (event.target.id === this.id) {
                this.tooltipIsOpen = true
            } else {
                this.tooltipIsOpen = false
            }
        },
        dispathSearch() {
            if (this.search.length >= 2) {
                this.loading = true
                this.$request.get('/market/search', { needData: 1 , search: this.search}, response => {
                    this.merchants = response.merchants
                    this.collections = response.collections
                    this.nft = response.nft
                    this.loading = false
                })
            }
        },
        pushToSearchPage() {
            this.$router.push({ name: 'search' , query: { search: this.search } })
        }
    },
    mounted() {
        document.addEventListener('click', this.closeTooltip)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeTooltip)
    }
}
</script>

<style lang="less">
    .simple-search-input {
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 100px;
        background-color: var(--white-20);
        backdrop-filter: blur(10px);
        padding: 0px 20px;
        transition: all 0.2s;
        width: 100%;
        position: relative;
        z-index: 1;

        &.-moz {
            background-image: url("./images/search-bg.webp");

            &:focus-within {
                background-image: none;
            }
        }

        > svg {
            path {
                transition: all 0.2s;
            }
        }

        > input {
            flex-grow: 1;
            margin: 0px 10px;
            background: none;
            outline: none;
            border: none;
            color: var(--white);
            font-size: 14px;
            line-height: 21px;

            &::placeholder {
                color: var(--white);
                opacity: 0.6;
            }
        }

        > .clear {
            &:hover {
                cursor: pointer;

                path {
                    fill: var(--blue-link);
                }
            }
        }

        > .search-popup {
            padding: 25px 30px;
            box-shadow: 0px 20px 40px var(--dark-blue-20);
            background-color: var(--white);
            color: var(--dark-blue);
            border-radius: 20px;
            position: absolute;
            top: calc(100% + 3px);
            left: 0;
            right: 0;
            min-height: 100px;

            &.-preloader {
                row-gap: 7px;
                display: flex;
                flex-direction: column;
            }

            > .group {
                margin-top: 20px;

                > .title {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 144%;
                    color: var(--dark-blue-40);
                }

                > .item {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    color: var(--dark-blue);

                    > .image-container {
                        width: 50px;
                        height: 50px;
                        border-radius: 100px;
                        overflow: hidden;

                        > * {
                            object-fit: contain;
                            width: 100%;
                        }
                    }

                    > .content {
                        margin-left: 10px;

                        > .title {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                        }

                        > .items {
                            color: var(--dark-blue-40);
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }

                    &.-creator {
                        > .content {
                            > .title {
                                display: flex;
                                align-items: center;
                                column-gap: 3px;
                            }
                        }
                    }

                    &.-item {
                        > .image-container {
                            border-radius: 10px;
                        }

                        > .content {
                            > .creator {
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }

            > .btn {
                margin-top: 20px;
            }
        }

        &:hover {
            background-color: var(--white-30);
        }

        &:focus-within {
            background-color: var(--white);
            box-shadow: 0px 10px 30px var(--dark-blue-30);

            input {
                color: var(--dark-blue);

                &::placeholder {
                    color: var(--dark-blue);
                }
            }
            > svg {
                path {
                    fill: var(--dark-blue);
                }
            }
        }
    }

    @media (max-width: 767px) {
        .simple-search-input {
            > .search-popup {
                padding: 15px 20px;

                > .btn {
                    height: 40px;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
    }
</style>