<template>
    <div class="market-home-banners">
        <div v-show="!$store.state.isMobile" class="container">
            <div class="banners" :class="bannersWrapperClass">
                <a class="banner" v-for="(item, index) in banners"
                :key="index" :href="item.url"
                @click.prevent="redirect(index, item.link)">
                    <span v-if="item.tag.text" class="tag" :class="bannersTagClass(item.tag.text)">
                        {{ item.tag.text }}
                    </span>

                    <div class="background">
                        <img class="" :src="item.image">
                    </div>

                    <div class="wrapper">
                        <h3 class="title">
                            {{ item.title }}
                        </h3>

                        <w-button
                            type="white-border"
                            size="large"
                            @click="redirect(index, item.link, true)"
                        >
                            Explore
                        </w-button>
                    </div>
                </a>
            </div>
        </div>

        <div v-show="$store.state.isMobile" class="container">

            <div class="banner-container">
                <div v-show="banners.length <= 1" ref="one-element" class="banner -one" :style="bannerHeight">
                    <span v-if="banners[0].tag.text" class="tag" :class="bannersTagClass(banners[0].tag.text)">
                        {{ banners[0].tag.text }}
                    </span>

                    <div class="background">
                        <img :src="banners[0].image">
                    </div>

                    <a :href="banners[0].link" class="wrapper">
                        <h3 class="title">
                            {{ banners[0].title }}
                        </h3>
                    </a>
                </div>
            </div>

            <swiper v-show="banners.length > 1" ref="swiper" class="banners"
            :options="swiperOptions">
                <swiper-slide v-for="(item, index) in banners" :key="index">
                    <div ref="banner" class="banner" :style="bannerHeight">
                        <span v-if="item.tag.text" class="tag" :class="bannersTagClass(item.tag.text)">
                            {{ item.tag.text }}
                        </span>

                        <div class="background">
                            <img :src="item.image">
                        </div>

                        <a :href="item.link" class="wrapper">
                            <h3 class="title">
                                {{ item.title }}
                            </h3>
                        </a>
                    </div>
                </swiper-slide>
            </swiper>

            <div class="swiper-pagination pagination" slot="pagination"></div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'market-banner-block',
    components: {
        'swiper': Swiper,
        'swiper-slide': SwiperSlide
    },
    props: {
        banners: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            banner: '',
            oneElement: ''
        }
    },
    computed: {
        bannersWrapperClass() {
            let bannersCount = this.banners.length

            switch (bannersCount) {
                case 1:
                    return '-one-element'
                case 2:
                    return '-two-elements'
                case 3:
                    return '-three-elements'
                case 4:
                    return '-four-elements'
                case 5:
                    return '-five-elements'
            }
        },
        bannerHeight() {
            if (this.banner && this.oneElement) {
                if (this.banners.length > 1) {
                    return `height: ${this.banner.width}px`;
                } else {
                    return `height: ${this.oneElement.width}px`;
                }
            }
        },
        swiperOptions() {
            return {
                slidesPerView: 'auto',
                spaceBetween: 10,
                centeredSlides: true,
                loop: this.banners.length > 1,
                loopFillGroupWithBlank: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets'
                }
            }
        },
    },
    methods: {
        bannersTagClass(tagName) {
            return `-${tagName.toLowerCase()}`
        },
        redirect(index, url, isButton = false) {
            if (index === 0 && !isButton) return
            window.open(url, '_blank')
        }
    },
    mounted() {
        this.banner = this.$refs.banner[0].getBoundingClientRect()
        this.oneElement = this.$refs['one-element'].getBoundingClientRect()
    }
}
</script>

<style lang="less">
.market-home-banners {
    padding: 60px 0;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .banners {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
            height: 500px;

            > .banner {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                border-radius: 20px;
                color: var(--white);
                overflow: hidden;

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    background: linear-gradient(52.4deg, rgba(0, 26, 84, 0.4) 20.28%, rgba(0, 26, 84, 0) 102.92%);
                    z-index: 1;
                }

                &:first-child {
                    cursor: default;
                }

                &:not(:first-child) {
                    cursor: pointer;

                    > .tag {
                        top: 35px;
                        left: 35px;
                    }

                    > .wrapper {
                        padding: 0 0 35px 35px;

                        > .title {
                            margin-bottom: 0;
                            max-width: 199px;
                            font-size: 24px;
                            line-height: 29px;
                        }

                        > .btn {
                            display: none;
                        }
                    }
                }

                > .tag {
                    position: absolute;
                    top: 50px;
                    left: 50px;
                    display: block;
                    padding: 2px 10px;
                    border-radius: 100px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                    text-transform: uppercase;
                    z-index: 2;

                    &.-new {
                        background-color: var(--green);
                    }

                    &.-hot {
                        background-color: #FF7A00;
                    }

                    &.-exclusive {
                        background-color: #E8439C;
                    }
                }

                > .background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    overflow: hidden;

                    > img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }

                > .wrapper {
                    position: relative;
                    padding: 0 0 50px 50px;
                    z-index: 2;

                    > .title {
                        max-width: 247px;
                        margin-bottom: 24px;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 40px;
                    }
                }
            }

            &.-one-element {
                > .banner {
                    &:nth-child(1) {
                        grid-column: 1 / span 4;
                    }
                }
            }

            &.-two-elements {
                > .banner {
                    &:nth-child(1) {
                        grid-column: 1 / span 3;
                    }

                    &:nth-child(2) {
                        grid-column: 4;
                    }
                }
            }

            &.-three-elements {
                > .banner {
                    &:nth-child(1) {
                        grid-column: 1 / span 3;
                        grid-row: 1 / span 2;
                    }

                    &:nth-child(2) {
                        grid-column: 4;
                        grid-row: 1;
                    }

                    &:nth-child(3) {
                        grid-column: 4;
                        grid-row: 2;
                    }
                }
            }

            &.-four-elements {
                > .banner {
                    &:nth-child(1) {
                        grid-column: 1 / span 2;
                        grid-row: 1 / span 2;
                    }

                    &:nth-child(2) {
                        grid-column: span 2;
                        grid-row: 1;
                        &:after {
                          background: #001A5466;
                        }
                    }

                    &:nth-child(3) {
                        grid-column: 3;
                        grid-row: 2;
                        &:after {
                          background: #001A5466;
                        }
                    }

                    &:nth-child(4) {
                        grid-column: 4;
                        grid-row: 2;
                        &:after {
                          background: #001A5466;
                        }
                    }
                }
            }

            &.-five-elements {
                > .banner {
                    &:nth-child(1) {
                        grid-column: 1 / span 2;
                        grid-row: 1 / span 2;
                    }

                    &:nth-child(2) {
                        grid-column: 3;
                        grid-row: 1;
                    }

                    &:nth-child(3) {
                        grid-column: 4;
                        grid-row: 1;
                    }

                    &:nth-child(4) {
                        grid-column: 3;
                        grid-row: 2;
                    }

                    &:nth-child(5) {
                        grid-column: 4;
                        grid-row: 2;
                    }
                }
            }
        }
    }
}

@media (max-width: 1439px) {
    .market-home-banners {
        padding: 50px 0;

        > .container {
            > .banners {
                &.-four-elements {
                    grid-template-columns: repeat(3, 1fr);
                    height: 636px;

                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 3;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(2) {
                            grid-column: 1;
                            grid-row: 3;
                        }

                        &:nth-child(3) {
                            grid-column: 2;
                            grid-row: 3;
                        }

                        &:nth-child(4) {
                            grid-column: 3;
                            grid-row: 3;
                        }
                    }
                }

                &.-five-elements {
                    height: 636px;

                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 2;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(2) {
                            grid-column: 3;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(3) {
                            grid-column: 1;
                            grid-row: 3;
                        }

                        &:nth-child(4) {
                            grid-column: 2;
                            grid-row: 3;
                        }

                        &:nth-child(5) {
                            grid-column: 3;
                            grid-row: 3;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .market-home-banners {
        > .container {
            > .banners {
                grid-gap: 18px;
                height: 418px;

                > .banner {
                    &:not(:first-child) {
                        > .wrapper {
                            > .title {
                                margin-bottom: 0;
                                font-size: 20px;
                                line-height: 25px;
                            }
                        }
                    }

                    > .wrapper {
                        padding: 0 0 37px 42px;

                        > .title {
                            max-width: 216px;
                            margin-bottom: 11px;
                            font-size: 28px;
                            line-height: 36px;
                        }

                        > .btn {
                            height: 50px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .market-home-banners {
        > .container {
            > .banners {
                grid-gap: 20px;
                height: 418px;

                &.-one-element {
                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 2;
                            grid-row: 1 / span 2;
                        }
                    }
                }

                &.-two-elements {
                    height: 638px;

                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 2;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(2) {
                            grid-column: 1 / span 2;
                            grid-row: 3;
                        }
                    }
                }

                &.-three-elements {
                    height: 638px;

                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 2;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(2) {
                            grid-column: 1;
                            grid-row: 3;
                        }

                        &:nth-child(3) {
                            grid-column: 2;
                            grid-row: 3;
                        }
                    }
                }

                &.-four-elements {
                    height: 858px;
                    grid-template-columns: repeat(2, 1fr);

                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 2;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(2) {
                            grid-column: 1 / span 2;
                            grid-row: 3;
                        }

                        &:nth-child(3) {
                            grid-column: 1;
                            grid-row: 4;
                        }

                        &:nth-child(4) {
                            grid-column: 2;
                            grid-row: 4;
                        }
                    }
                }

                &.-five-elements {
                    height: 858px;

                    > .banner {
                        &:nth-child(1) {
                            grid-column: 1 / span 2;
                            grid-row: 1 / span 2;
                        }

                        &:nth-child(2) {
                            grid-column: 1;
                            grid-row: 3;
                        }

                        &:nth-child(3) {
                            grid-column: 2;
                            grid-row: 3;
                        }

                        &:nth-child(4) {
                            grid-column: 1;
                            grid-row: 4;
                        }

                        &:nth-child(5) {
                            grid-column: 2;
                            grid-row: 4;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .market-home-banners {
        padding: 20px 0;
        margin: 0px -15px;

        > .container {

            .banner-container {
                padding: 0px 15px;

                .banner {
                    &.-one {
                        position: relative;
                        width: 100%;
                        border-radius: 20px;
                        color: var(--white);
                        overflow: hidden;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                            background: linear-gradient(52.4deg, rgba(0, 26, 84, 0.4) 20.28%, rgba(0, 26, 84, 0) 102.92%);
                            z-index: 1;
                        }

                        > .tag {
                            position: absolute;
                            top: 15px;
                            left: 20px;
                            display: block;
                            padding: 2px 10px;
                            border-radius: 100px;
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 15px;
                            text-transform: uppercase;
                            z-index: 2;

                            &.-new {
                                background-color: var(--green);
                            }

                            &.-hot {
                                background-color: #FF7A00;
                            }

                            &.-exclusive {
                                background-color: #E8439C;
                            }
                        }

                        > .background {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                            z-index: 0;
                            overflow: hidden;

                            > img {
                                object-fit: cover;
                                height: 100%;
                                width: 100%;
                            }
                        }

                        > .wrapper {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            height: 100%;
                            padding: 0 0 20px 22px;
                            color: var(--white);
                            z-index: 2;

                            > .title {
                                max-width: 139px;
                                font-size: 18px;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }

            > .banners {
                display: flex;
                height: auto;

                > .swiper-wrapper {
                    > .swiper-slide {
                        width: calc(100% - 30px);
                    }

                    .banner {
                        position: relative;
                        width: 100%;
                        border-radius: 20px;
                        color: var(--white);
                        overflow: hidden;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                            background: linear-gradient(52.4deg, rgba(0, 26, 84, 0.4) 20.28%, rgba(0, 26, 84, 0) 102.92%);
                            z-index: 1;
                        }

                        > .tag {
                            position: absolute;
                            top: 15px;
                            left: 20px;
                            display: block;
                            padding: 2px 10px;
                            border-radius: 100px;
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 15px;
                            text-transform: uppercase;
                            z-index: 2;

                            &.-new {
                                background-color: var(--green);
                            }

                            &.-hot {
                                background-color: #FF7A00;
                            }

                            &.-exclusive {
                                background-color: #E8439C;
                            }
                        }

                        > .background {
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                            z-index: 0;
                            overflow: hidden;

                            > img {
                                object-fit: cover;
                                height: 100%;
                                width: 100%;
                            }
                        }

                        > .wrapper {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            height: 100%;
                            padding: 0 0 20px 22px;
                            color: var(--white);
                            z-index: 2;

                            > .title {
                                max-width: 139px;
                                font-size: 18px;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }

            > .pagination {
                display: flex;
                justify-content: center;
                column-gap: 6px;
                -moz-column-gap: 6px;
                width: 100%;
                margin-top: 10px;

                > .grey-bullets {
                    width: 6px;
                    height: 6px;
                    background-color: var(--dark-blue-20);
                    transition: .3s;
                }

                > .swiper-pagination-bullet-active {
                    width: 26px;
                    height: 6px;
                    border-radius: 100px;
                    background-color: var(--blue-link);
                    transition: .3s;
                }
            }
        }
    }
}
</style>
