<template>
    <div class="market-search-preloader">
        <div class="image"></div>

        <div class="container">
            <div class="title"></div>

            <div class="amount-items"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'market-search-preloader',
}
</script>

<style lang="less">
.market-search-preloader {
    width: 100%;
    display: flex;

    > .image {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: var(--preloader-background);
        display: flex;
    }

    > .container {
        margin-left: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        padding: 11px;

        > .title {
            width: 50%;
            height: 14px;
            border-radius: 7px;
            background-color: var(--preloader-background);
        }

        > .amount-items {
            width: 25%;
            height: 8px;
            border-radius: 4px;
            background-color: var(--preloader-background);
        }
    }
}
</style>