<template>
    <div class="market-home-banners-preloader">
        <div class="container">
            <div class="banners">
                <div ref="banner" class="banner" :style="bannerHeight">
                    <div class="tag"></div>
                    <div class="title"></div>
                    <div class="subtitle"></div>
                    <div class="btn"></div>
                </div>
                <div class="banner">
                    <div class="tag"></div>
                    <div class="title"></div>
                    <div class="subtitle"></div>
                    <div class="btn"></div>
                </div>
                <div class="banner">
                    <div class="tag"></div>
                    <div class="title"></div>
                    <div class="subtitle"></div>
                    <div class="btn"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "banners-preloader",
    data() {
        return {
            banner: '',
        }
    },
    computed: {
        bannerHeight() {
            if (this.$store.state.isMobile) {
                return `height: ${this.banner.width}px`;
            }
        }
    },
    mounted() {
        this.banner = this.$refs.banner.getBoundingClientRect()
    }
}
</script>

<style lang="less">
.market-home-banners-preloader {
    padding: 60px 0;

    > .container {
        width: var(--main-width-full);
        margin: 0 auto;

        > .banners {
            display: grid;
            grid-template-columns: repeat(3 1fr);
            grid-gap: 20px;
            height: 500px;

            > .banner {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 0 0 50px 50px;
                border-radius: 20px;
                background-color: var(--preloader-background);

                &:not(:first-child) {
                    padding: 0 0 35px 35px;

                    > .tag {
                        top: 35px;
                        left: 35px;
                    }

                    > .title {
                        width: 204px;
                        height: 24px;
                        margin-bottom: 5px;
                        border-radius: 100px;
                        background-color: var(--white);
                    }

                    > .subtitle {
                        width: 120px;
                        height: 24px;
                        margin-bottom: 0;
                        border-radius: 100px;
                        background-color: var(--white);
                    }

                    > .btn {
                        display: none;
                    }
                }

                &:nth-child(1) {
                    grid-column: 1 / span 2;
                    grid-row: 1 / span 2;
                }

                &:nth-child(2) {
                    grid-column: 3;
                    grid-row: 1;
                }

                &:nth-child(3) {
                    grid-column: 3;
                    grid-row: 2;
                }

                > .tag {
                    position: absolute;
                    top: 50px;
                    left: 50px;
                    display: block;
                    width: 73px;
                    height: 20px;
                    border-radius: 100px;
                    background-color: var(--white);
                    z-index: 2;
                }

                > .title {
                    width: 332px;
                    height: 34px;
                    margin-bottom: 5px;
                    border-radius: 100px;
                    background-color: var(--white);
                }

                > .subtitle {
                    width: 180px;
                    height: 34px;
                    margin-bottom: 31px;
                    border-radius: 100px;
                    background-color: var(--white);
                }

                > .btn {
                    width: 148px;
                    height: 60px;
                    border-radius: 100px;
                    background-color: var(--white);
                }
            }
        }
    }
}

@media (max-width: 1439px) {
    .market-home-banners-preloader {
        padding: 50px 0;

        > .container {
            > .banners {
                height: 636px;
            }
        }
    }
}

@media (max-width: 1279px) {
    .market-home-banners-preloader {
        > .container {
            > .banners {
                grid-gap: 18px;

                > .banner {
                    padding: 0 0 37px 42px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .market-home-banners-preloader {
        > .container {
            > .banners {
                grid-template-columns: repeat(2 1fr);
                grid-gap: 20px;
                height: 638px;

                > .banner {
                    padding: 0 0 37px 42px;

                    &:nth-child(1) {
                        grid-column: 1 / span 2;
                        grid-row: 1 / span 2;
                    }

                    &:nth-child(2) {
                        grid-column: 1;
                        grid-row: 3;
                    }

                    &:nth-child(3) {
                        grid-column: 2;
                        grid-row: 3;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .market-home-banners-preloader {
        padding: 20px 0;

        > .container {
            > .banners {
                display: flex;
                height: inherit;

                > .banner {
                    width: 100%;
                    padding: 0 0 20px 22px;

                    &:nth-child(2),
                    &:nth-child(3) {
                        display: none;
                    }

                    > .tag {
                        top: 15px;
                        left: 20px;
                    }

                    > .title {
                        width: 162px;
                        height: 18px;
                        margin-bottom: 5px;
                        border-radius: 100px;
                        background-color: var(--white);
                    }

                    > .subtitle {
                        height: 26px;
                        margin-bottom: 0;
                    }

                    > .btn {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
